import * as React from "react"
import { SVGProps } from "react"

// Constants
import { xmlNamespace } from "../../constants/svgUrl"

export const HeroSectionSmallSvg = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      viewBox="0 0 343 244"
      fill="none"
      xmlns={xmlNamespace}
      {...props}
      style={{
        width: "100%",
        borderTopLeftRadius: "48px",
        borderTopRightRadius: "48px",
      }}
    >
      <path
        opacity={0.25}
        d="M343 0H0V81.7877C64.1463 288.302 213.378 -84.514 343 244V0Z"
        fill={props.color}
      />
    </svg>
  )
}

export const HeroSectionLargeSvg = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      viewBox="0 0 1264 256"
      fill="none"
      xmlns={xmlNamespace}
      {...props}
      style={{
        width: "100%",
        borderTopLeftRadius: "48px",
        borderTopRightRadius: "48px",
      }}
    >
      <path
        opacity={0.25}
        d="M1264 0H0V85.8101C236.388 302.48 786.326 -88.6704 1264 256V0Z"
        fill={props.color}
      />
    </svg>
  )
}

export const CardSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 407 116"
    fill="none"
    xmlns={xmlNamespace}
    {...props}
    style={{
      width: "100%",
      borderTopLeftRadius: "12px",
      borderTopRightRadius: "12px",
    }}
  >
    <path
      opacity={0.25}
      d="M407 -15H0V28.9106C76.1153 139.785 253.192 -60.3743 407 116V-15Z"
      fill={props.color}
    />
  </svg>
)

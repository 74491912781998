import React, { FC } from "react"
import styled from "styled-components"
import ContentWrapper from "../../ContentWrapper"
import DeepdiveCards from "../deepdive-cards"

// styles
import mq from "../../../styles/breakpoints"
import colors from "../../../styles/colors"

// Types
import { IDeepdiveCards } from "src/prismic-types/slices/deep-dive-cards.js"

interface IProps {
  data: IDeepdiveCards
}
const DeepdivePage: FC<IProps> = ({ data }) => {
  return (
    <Container>
      <ContentWrapper>
        <DeepdiveCards
          key="deepdive-cards"
          title={data.title}
          description={data.description}
          cards={data.cards}
          isLandingPage
        />
      </ContentWrapper>
    </Container>
  )
}

export default DeepdivePage

const Container = styled.section`
  position: relative;
  padding: 96px 0 80px;
  color: ${colors.text};
  background-color: ${colors.main["900"]};

  ${mq.from.S`
    padding: 104px 0 80px;
  `}

  ${mq.from.M`
    padding: 168px 0 80px;
  `}
`

import React, { FC } from "react"
import styled from "styled-components"

// Components
import DeepdiveCard from "./DeepdiveCard"
import CustomPrismicTitle from "../../atoms/CustomPrismicTitle"
import Button from "../../atoms/Button"

// custom hooks
import { useLocale } from "../../../hooks/useLocale"

// Styles
import mq from "../../../styles/breakpoints"
import colors from "../../../styles/colors"

//  Types
import { IDeepdiveCards } from "../../../prismic-types/slices/deep-dive-cards"

interface IProps extends IDeepdiveCards {
  isLandingPage: boolean
}

const DeepdiveCards: FC<IProps> = ({
  title,
  description,
  button,
  cards,
  isLandingPage,
}) => {
  const { getLocalizedLink } = useLocale()
  return (
    <Conatiner isLandingPage={isLandingPage}>
      <HeaderSection>
        <div>
          <CustomPrismicTitle data={title} />
          {!!description && <TagLine>{description}</TagLine>}
        </div>
        {!!button?.href && (
          <StyledButtonFirst
            fullWidth={false}
            href={getLocalizedLink(button.href)}
            label={button?.label}
            variant="secondary"
          />
        )}
      </HeaderSection>
      <CardsSection>
        {cards.map((card) => (
          <DeepdiveCard
            key={card.label.text}
            label={card.label}
            description={card.description}
            link={card.link}
            backgroundColor={card.backgroundColor}
            foregroundColor={card.foregroundColor}
          />
        ))}
      </CardsSection>

      {!!button?.href && (
        <StyledButtonSecond
          fullWidth={true}
          href={getLocalizedLink(button.href)}
          label={button.label}
          variant="secondary"
        />
      )}
    </Conatiner>
  )
}

export default DeepdiveCards

const Conatiner = styled.div<{ isLandingPage: boolean }>`
  display: flex;
  flex-direction: column;
  gap: ${({ isLandingPage }) => (isLandingPage ? "64px" : "32px")};
`

const CardsSection = styled.section`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  gap: 18px;

  ${mq.from.SM`
    grid-template-columns: repeat(2, 1fr);
  `}

  ${mq.from.L`
    grid-template-columns: repeat(3, 1fr);
    gap: 24px;
  `}

  ${mq.from.XL`
    gap: 30px;
  `}
`
const HeaderSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const TagLine = styled.p`
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
  color: ${colors.main["300"]};
  letter-spacing: -1%;
  margin-bottom: 0px;

  ${mq.from.M`
  font-size: 20px;
  line-height: 32px;
  `}
`

const StyledButtonFirst = styled(Button)`
  display: none;

  ${mq.from.XSM`
    display: flex;
    justify-content: center;
    align-items: center;
  `}
`
const StyledButtonSecond = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  ${mq.from.XSM`
  display: none;
  `}
`
